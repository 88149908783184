import { graphql } from "gatsby"
import React from "react"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
import Article from "../components/Article"
import Profile from "../components/Profile"
const IndexPage = ({ data }) => {
  const blogs = data.allContentfulBlogPost.nodes
  return (
    <Layout>
      <SiteMetadata
        title="Home - Nick Partridge"
        description="My home page."
      />
      <div className="md:flex">
        <aside className="p-6 md:p-8 main__aside">
          <Profile />
        </aside>
        <div className="main__main md:px-12 p-6 md:py-8">
          {blogs.map(blog => (
            <Article
              key={blog.slug}
              slug={blog.slug}
              title={blog.title}
              date={blog.createdAt}
              excerpt={blog.blogExcerptv2.blogExcerptv2}
            />
          ))}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulBlogPost {
      nodes {
        slug
        title
        createdAt(formatString: "MMMM YYYY")
        blogExcerptv2 {
          blogExcerptv2
        }
      }
    }
  }
`

export default IndexPage

import React from 'react';
import {Link} from 'gatsby';
const Article = ({date, title, slug, excerpt }) => {
  return (
     <article className="mb-8">
      <span className="text-orange-400 font-bold text-sm">{date.toUpperCase()}</span>
      <h2 className="text-2xl font-bold mb-4 hover:underline">
  <Link to={`/blog/${slug}`}>{title}</Link>
      </h2>
      <p>
        {excerpt}
      </p>
      <Link to={`/blog/${slug}`} className="text-blue-600 hover:underline mt-4 block">
        Read more...
      </Link>
    </article>
  );
}

export default Article;
